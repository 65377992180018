export const RH_TEAM_ID = '77715ff5-2044-4fec-b207-21993dd0bee7';
export const RH_TEAM_PHONE_NUMBER = '(844) 748-3240';
export const RH_TEAM_CARE_EMAIL = 'care@remodelhealth.com';
export const RH_TEAM_LAUNCH_EMAIL = 'launch@remodelhealth.com';
export const RH_MEDICARE_EMAIL = 'medicare@remodelhealth.com';

export const CCM_TEAM_ID = '69CA6B25-B64F-4789-8D47-DA7082B99F6C';

export const CHARLESTON_SOUTHERN_UNIVERSITY_LOGO_URL =
    'assets/images/Charleston-Southern-University-Logo.png';
export const CHARLESTON_SOUTHERN_UNIVERSITY_TEAM_ID = '8d87dc34-488b-4fbc-8c8c-99e869780263';

export const UNIVERSITY_OF_SAINT_FRANCIS_LOGO_URL =
    'assets/images/university-of-saint-francis-logo.png';
export const UNIVERSITY_OF_SAINT_FRANCIS_TEAM_ID = '195308e9-1e4d-4a96-8562-8671877c2e37';
